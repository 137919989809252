(function(global,factory){typeof exports==='object'&&typeof module!=='undefined'?module.exports=factory(require('jquery'),require('popper.js'),require('./util.js')):typeof define==='function'&&define.amd?define(['jquery','popper.js','./util.js'],factory):(global.Dropdown=factory(global.jQuery,global.Popper,global.Util))}(this,(function($,Popper,Util){'use strict';$=$&&$.hasOwnProperty('default')?$['default']:$;Popper=Popper&&Popper.hasOwnProperty('default')?Popper['default']:Popper;Util=Util&&Util.hasOwnProperty('default')?Util['default']:Util;function _defineProperties(target,props){for(var i=0;i<props.length;i++){var descriptor=props[i];descriptor.enumerable=descriptor.enumerable||!1;descriptor.configurable=!0;if("value" in descriptor)descriptor.writable=!0;Object.defineProperty(target,descriptor.key,descriptor)}}
function _createClass(Constructor,protoProps,staticProps){if(protoProps)_defineProperties(Constructor.prototype,protoProps);if(staticProps)_defineProperties(Constructor,staticProps);return Constructor}
function _defineProperty(obj,key,value){if(key in obj){Object.defineProperty(obj,key,{value:value,enumerable:!0,configurable:!0,writable:!0})}else{obj[key]=value}
return obj}
function _objectSpread(target){for(var i=1;i<arguments.length;i++){var source=arguments[i]!=null?arguments[i]:{};var ownKeys=Object.keys(source);if(typeof Object.getOwnPropertySymbols==='function'){ownKeys=ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym){return Object.getOwnPropertyDescriptor(source,sym).enumerable}))}
ownKeys.forEach(function(key){_defineProperty(target,key,source[key])})}
return target}
var NAME='dropdown';var VERSION='4.2.1';var DATA_KEY='bs.dropdown';var EVENT_KEY="."+DATA_KEY;var DATA_API_KEY='.data-api';var JQUERY_NO_CONFLICT=$.fn[NAME];var ESCAPE_KEYCODE=27;var SPACE_KEYCODE=32;var TAB_KEYCODE=9;var ARROW_UP_KEYCODE=38;var ARROW_DOWN_KEYCODE=40;var RIGHT_MOUSE_BUTTON_WHICH=3;var REGEXP_KEYDOWN=new RegExp(ARROW_UP_KEYCODE+"|"+ARROW_DOWN_KEYCODE+"|"+ESCAPE_KEYCODE);var Event={HIDE:"hide"+EVENT_KEY,HIDDEN:"hidden"+EVENT_KEY,SHOW:"show"+EVENT_KEY,SHOWN:"shown"+EVENT_KEY,CLICK:"click"+EVENT_KEY,CLICK_DATA_API:"click"+EVENT_KEY+DATA_API_KEY,KEYDOWN_DATA_API:"keydown"+EVENT_KEY+DATA_API_KEY,KEYUP_DATA_API:"keyup"+EVENT_KEY+DATA_API_KEY};var ClassName={DISABLED:'disabled',SHOW:'show',DROPUP:'dropup',DROPRIGHT:'dropright',DROPLEFT:'dropleft',MENURIGHT:'dropdown-menu-right',MENULEFT:'dropdown-menu-left',POSITION_STATIC:'position-static'};var Selector={DATA_TOGGLE:'[data-toggle="dropdown"]',FORM_CHILD:'.dropdown form',MENU:'.dropdown-menu',NAVBAR_NAV:'.navbar-nav',VISIBLE_ITEMS:'.dropdown-menu .dropdown-item:not(.disabled):not(:disabled)'};var AttachmentMap={TOP:'top-start',TOPEND:'top-end',BOTTOM:'bottom-start',BOTTOMEND:'bottom-end',RIGHT:'right-start',RIGHTEND:'right-end',LEFT:'left-start',LEFTEND:'left-end'};var Default={offset:0,flip:!0,boundary:'scrollParent',reference:'toggle',display:'dynamic'};var DefaultType={offset:'(number|string|function)',flip:'boolean',boundary:'(string|element)',reference:'(string|element)',display:'string'};var Dropdown=function(){function Dropdown(element,config){this._element=element;this._popper=null;this._config=this._getConfig(config);this._menu=this._getMenuElement();this._inNavbar=this._detectNavbar();this._addEventListeners()}
var _proto=Dropdown.prototype;_proto.toggle=function toggle(){if(this._element.disabled||$(this._element).hasClass(ClassName.DISABLED)){return}
var parent=Dropdown._getParentFromElement(this._element);var isActive=$(this._menu).hasClass(ClassName.SHOW);Dropdown._clearMenus();if(isActive){return}
var relatedTarget={relatedTarget:this._element};var showEvent=$.Event(Event.SHOW,relatedTarget);$(parent).trigger(showEvent);if(showEvent.isDefaultPrevented()){return}
if(!this._inNavbar){if(typeof Popper==='undefined'){throw new TypeError('Bootstrap\'s dropdowns require Popper.js (https://popper.js.org/)')}
var referenceElement=this._element;if(this._config.reference==='parent'){referenceElement=parent}else if(Util.isElement(this._config.reference)){referenceElement=this._config.reference;if(typeof this._config.reference.jquery!=='undefined'){referenceElement=this._config.reference[0]}}
if(this._config.boundary!=='scrollParent'){$(parent).addClass(ClassName.POSITION_STATIC)}
this._popper=new Popper(referenceElement,this._menu,this._getPopperConfig())}
if('ontouchstart' in document.documentElement&&$(parent).closest(Selector.NAVBAR_NAV).length===0){$(document.body).children().on('mouseover',null,$.noop)}
this._element.focus();this._element.setAttribute('aria-expanded',!0);$(this._menu).toggleClass(ClassName.SHOW);$(parent).toggleClass(ClassName.SHOW).trigger($.Event(Event.SHOWN,relatedTarget))};_proto.show=function show(){if(this._element.disabled||$(this._element).hasClass(ClassName.DISABLED)||$(this._menu).hasClass(ClassName.SHOW)){return}
var relatedTarget={relatedTarget:this._element};var showEvent=$.Event(Event.SHOW,relatedTarget);var parent=Dropdown._getParentFromElement(this._element);$(parent).trigger(showEvent);if(showEvent.isDefaultPrevented()){return}
$(this._menu).toggleClass(ClassName.SHOW);$(parent).toggleClass(ClassName.SHOW).trigger($.Event(Event.SHOWN,relatedTarget))};_proto.hide=function hide(){if(this._element.disabled||$(this._element).hasClass(ClassName.DISABLED)||!$(this._menu).hasClass(ClassName.SHOW)){return}
var relatedTarget={relatedTarget:this._element};var hideEvent=$.Event(Event.HIDE,relatedTarget);var parent=Dropdown._getParentFromElement(this._element);$(parent).trigger(hideEvent);if(hideEvent.isDefaultPrevented()){return}
$(this._menu).toggleClass(ClassName.SHOW);$(parent).toggleClass(ClassName.SHOW).trigger($.Event(Event.HIDDEN,relatedTarget))};_proto.dispose=function dispose(){$.removeData(this._element,DATA_KEY);$(this._element).off(EVENT_KEY);this._element=null;this._menu=null;if(this._popper!==null){this._popper.destroy();this._popper=null}};_proto.update=function update(){this._inNavbar=this._detectNavbar();if(this._popper!==null){this._popper.scheduleUpdate()}};_proto._addEventListeners=function _addEventListeners(){var _this=this;$(this._element).on(Event.CLICK,function(event){event.preventDefault();event.stopPropagation();_this.toggle()})};_proto._getConfig=function _getConfig(config){config=_objectSpread({},this.constructor.Default,$(this._element).data(),config);Util.typeCheckConfig(NAME,config,this.constructor.DefaultType);return config};_proto._getMenuElement=function _getMenuElement(){if(!this._menu){var parent=Dropdown._getParentFromElement(this._element);if(parent){this._menu=parent.querySelector(Selector.MENU)}}
return this._menu};_proto._getPlacement=function _getPlacement(){var $parentDropdown=$(this._element.parentNode);var placement=AttachmentMap.BOTTOM;if($parentDropdown.hasClass(ClassName.DROPUP)){placement=AttachmentMap.TOP;if($(this._menu).hasClass(ClassName.MENURIGHT)){placement=AttachmentMap.TOPEND}}else if($parentDropdown.hasClass(ClassName.DROPRIGHT)){placement=AttachmentMap.RIGHT}else if($parentDropdown.hasClass(ClassName.DROPLEFT)){placement=AttachmentMap.LEFT}else if($(this._menu).hasClass(ClassName.MENURIGHT)){placement=AttachmentMap.BOTTOMEND}
return placement};_proto._detectNavbar=function _detectNavbar(){return $(this._element).closest('.navbar').length>0};_proto._getPopperConfig=function _getPopperConfig(){var _this2=this;var offsetConf={};if(typeof this._config.offset==='function'){offsetConf.fn=function(data){data.offsets=_objectSpread({},data.offsets,_this2._config.offset(data.offsets)||{});return data}}else{offsetConf.offset=this._config.offset}
var popperConfig={placement:this._getPlacement(),modifiers:{offset:offsetConf,flip:{enabled:this._config.flip},preventOverflow:{boundariesElement:this._config.boundary}}};if(this._config.display==='static'){popperConfig.modifiers.applyStyle={enabled:!1}}
return popperConfig};Dropdown._jQueryInterface=function _jQueryInterface(config){return this.each(function(){var data=$(this).data(DATA_KEY);var _config=typeof config==='object'?config:null;if(!data){data=new Dropdown(this,_config);$(this).data(DATA_KEY,data)}
if(typeof config==='string'){if(typeof data[config]==='undefined'){throw new TypeError("No method named \""+config+"\"")}
data[config]()}})};Dropdown._clearMenus=function _clearMenus(event){if(event&&(event.which===RIGHT_MOUSE_BUTTON_WHICH||event.type==='keyup'&&event.which!==TAB_KEYCODE)){return}
var toggles=[].slice.call(document.querySelectorAll(Selector.DATA_TOGGLE));for(var i=0,len=toggles.length;i<len;i++){var parent=Dropdown._getParentFromElement(toggles[i]);var context=$(toggles[i]).data(DATA_KEY);var relatedTarget={relatedTarget:toggles[i]};if(event&&event.type==='click'){relatedTarget.clickEvent=event}
if(!context){continue}
var dropdownMenu=context._menu;if(!$(parent).hasClass(ClassName.SHOW)){continue}
if(event&&(event.type==='click'&&/input|textarea/i.test(event.target.tagName)||event.type==='keyup'&&event.which===TAB_KEYCODE)&&$.contains(parent,event.target)){continue}
var hideEvent=$.Event(Event.HIDE,relatedTarget);$(parent).trigger(hideEvent);if(hideEvent.isDefaultPrevented()){continue}
if('ontouchstart' in document.documentElement){$(document.body).children().off('mouseover',null,$.noop)}
toggles[i].setAttribute('aria-expanded','false');$(dropdownMenu).removeClass(ClassName.SHOW);$(parent).removeClass(ClassName.SHOW).trigger($.Event(Event.HIDDEN,relatedTarget))}};Dropdown._getParentFromElement=function _getParentFromElement(element){var parent;var selector=Util.getSelectorFromElement(element);if(selector){parent=document.querySelector(selector)}
return parent||element.parentNode};Dropdown._dataApiKeydownHandler=function _dataApiKeydownHandler(event){if(/input|textarea/i.test(event.target.tagName)?event.which===SPACE_KEYCODE||event.which!==ESCAPE_KEYCODE&&(event.which!==ARROW_DOWN_KEYCODE&&event.which!==ARROW_UP_KEYCODE||$(event.target).closest(Selector.MENU).length):!REGEXP_KEYDOWN.test(event.which)){return}
event.preventDefault();event.stopPropagation();if(this.disabled||$(this).hasClass(ClassName.DISABLED)){return}
var parent=Dropdown._getParentFromElement(this);var isActive=$(parent).hasClass(ClassName.SHOW);if(!isActive||isActive&&(event.which===ESCAPE_KEYCODE||event.which===SPACE_KEYCODE)){if(event.which===ESCAPE_KEYCODE){var toggle=parent.querySelector(Selector.DATA_TOGGLE);$(toggle).trigger('focus')}
$(this).trigger('click');return}
var items=[].slice.call(parent.querySelectorAll(Selector.VISIBLE_ITEMS));if(items.length===0){return}
var index=items.indexOf(event.target);if(event.which===ARROW_UP_KEYCODE&&index>0){index--}
if(event.which===ARROW_DOWN_KEYCODE&&index<items.length-1){index++}
if(index<0){index=0}
items[index].focus()};_createClass(Dropdown,null,[{key:"VERSION",get:function get(){return VERSION}},{key:"Default",get:function get(){return Default}},{key:"DefaultType",get:function get(){return DefaultType}}]);return Dropdown}();$(document).on(Event.KEYDOWN_DATA_API,Selector.DATA_TOGGLE,Dropdown._dataApiKeydownHandler).on(Event.KEYDOWN_DATA_API,Selector.MENU,Dropdown._dataApiKeydownHandler).on(Event.CLICK_DATA_API+" "+Event.KEYUP_DATA_API,Dropdown._clearMenus).on(Event.CLICK_DATA_API,Selector.DATA_TOGGLE,function(event){event.preventDefault();event.stopPropagation();Dropdown._jQueryInterface.call($(this),'toggle')}).on(Event.CLICK_DATA_API,Selector.FORM_CHILD,function(e){e.stopPropagation()});$.fn[NAME]=Dropdown._jQueryInterface;$.fn[NAME].Constructor=Dropdown;$.fn[NAME].noConflict=function(){$.fn[NAME]=JQUERY_NO_CONFLICT;return Dropdown._jQueryInterface};return Dropdown})))